import React from 'react'

export default function Footer() {
    return (
        <footer className="flex text-center px-4 py-8 justify-center border-t border-gray-200 w-full">
            <p className="text-sm">
                Copyright {new Date().getFullYear()} • All Rights Reserved Driver Soul Concept
            </p>
        </footer>
    )
}
