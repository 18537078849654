import React from 'react'
import propTypes from 'prop-types'

export default function ContactSection({ Title, Action, Text, Icon }) {
    return (
        <a href={Action} className='w-40 flex-column my-5 text-center'>
            <div className='bg-green-950 p-3 rounded-full mx-auto my-5 w-fit'>
                <Icon color='#FFFFFFFF' size={"1.5em"} />
            </div>
            <div className="text-xl font-bold">
                {Title}
            </div>
            <div>
            <div> {Text} </div>
            </div>
        </a>
    )
}

ContactSection.propTypes = {
    Title: propTypes.string.isRequired,
    Text: propTypes.string.isRequired,
    Icon: propTypes.object.isRequired
}