import React from 'react'
import Hero from '../parts/HomePage/Hero'
import Imports from '../parts/HomePage/Imports'
import SuccessCases from '../parts/HomePage/SuccessCases'
// import useScrollAnchors from '../helpers/hooks/useScrollAnchors'
// import useModalDom from '../helpers/hooks/useModalDom'

export default function Home() {
    // useScrollAnchors();
    // useModalDom();
    return (
        <div >
            <Hero />
            <SuccessCases />
            <Imports />
        </div>
    )
}
