import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import HomePage from "./pages/HomePage";
import Contacts from "./pages/Contacts";
// import Showroom from "./pages/Showroom";
import Details from "./pages/Details";
import NotFound from "./pages/NotFound";

import Header from './parts/Header'
import Footer from './parts/Footer'

function App() {
  return (
    <div className="App flex flex-col justify-center min-h-screen items-center">
      <Router>
        <Header theme={"white"} />
        <Routes >
          <Route exact path="/" element={<HomePage />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/details/:idc" element={<Details />} />
          {/* <Route path="/showroom" element={<Showroom />} /> */}
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
