import React from 'react'
import { NavLink } from 'react-router-dom'

export default function NotFound() {
    return (
        <page className="flex grow flex-col py-6 md:py-16 px-4 w-full max-w-screen-xl">
            <div className="flex flex-col grow items-center justify-center">
                        <h2 className="text-3xl font-semibold mb-6">
                            404 NOT FOUND
                        </h2>
                        <p className="text-lg mb-12">
                            Looks like this page not found!
                        </p>
                        <NavLink to={"/"} 
                        className="text-gray-900 
                            bg-red-200 
                            focus:outline-none 
                            py-3 
                            rounded-full 
                            text-lg 
                            focus:text-black 
                            transition-all 
                            duration-200 
                            px-8 
                            cursor-pointer"
                        >
                            Back to Shop
                        </NavLink>
                </div>
        </page>
    )
}
