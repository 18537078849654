import React from 'react'

export default function Imports() {
    return (
        <section className="flex py-16 px-4" id="browse-the-room">
            <div className="container mx-auto">
                <div className="flex flex-start mb-4">
                    <h3 className="text-2xl capitalize font-semibold">
                        Importações
                        <br className="" />
                    </h3>
                </div>
                <div className="grid grid-rows-2 grid-cols-9 gap-4">
                    <div
                        className="relative col-span-9 row-span-1 md:col-span-4 card"
                        style={{ height: 180 }}
                    >
                        <div className="card-shadow rounded-xl">
                            <img
                                src="./images/content2/porsche-1.webp"
                                alt=""
                                className="w-full h-full object-cover object-center overlay overflow-hidden rounded-xl"
                            />
                        </div>
                        <div
                            className="overlay left-0 top-0 bottom-0 flex justify-center flex-col pl-48 md:pl-72"
                        >
                            <h5 className="text-lg font-semibold text-white">Porsche Panamera</h5>
                            <span className="text-white"></span>
                        </div>
                        <a href="/details" className="stretched-link">
                        </a>
                    </div>
                    <div
                        className="relative col-span-9 row-span-1 md:col-span-2 md:row-span-2 card"
                    >
                        <div className="card-shadow rounded-xl">
                            <img
                                src="./images/content2/lamborghini-1.jpeg"
                                alt=""
                                className="w-full h-full object-cover object-center overlay overflow-hidden rounded-xl"
                            />
                        </div>
                        <div
                            className="overlay right-0 left-0 top-0 bottom-0 md:bottom-auto flex justify-center md:items-center flex-col pl-48 md:pl-0 pt-0 md:pt-12"
                        >
                            <h5 className="text-lg font-semibold text-white">Lamborghini</h5>
                            <span className="text-white">2 modelos</span>
                        </div>
                        <a href="/details" className="stretched-link">
                        </a>
                    </div>
                    <div
                        className="relative col-span-9 row-span-1 md:col-span-3 md:row-span-2 card"
                    >
                        <div className="card-shadow rounded-xl">
                            <img
                                src="./images/content2/tesla-1.webp"
                                alt=""
                                className="w-full h-full object-cover object-center overlay overflow-hidden rounded-xl"
                            />
                        </div>
                        <div
                            className="overlay right-0 left-0 top-0 bottom-0 md:bottom-auto flex justify-center md:items-center flex-col pl-48 md:pl-0 pt-0 md:pt-12"
                        >
                            <h5 className="text-lg font-semibold">Tesla</h5>
                            <span className="">5 modelos</span>
                        </div>
                        <a href="/details" className="stretched-link">
                        </a>
                    </div>
                    <div className="relative col-span-9 row-span-1 md:col-span-4 card">
                        <div className="card-shadow rounded-xl">
                            <img
                                src="./images/content2/mercedes-1.jpeg"
                                alt=""
                                className="w-full h-full object-cover object-center overlay overflow-hidden rounded-xl"
                            />
                        </div>
                        <div
                            className="overlay left-0 top-0 bottom-0 flex justify-center flex-col pl-48 md:pl-72"
                        >
                            <h5 className="text-lg font-semibold text-white">Mercedes</h5>
                            <span className="text-white">20 modelos</span>
                        </div>
                        <a href="/details" className="stretched-link">
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}
