import React from 'react'
import { FaPhoneAlt, FaEnvelope, FaHome, FaInstagram, FaFacebook } from 'react-icons/fa';
import ContactSection from '../components/ContactSection/ContactSection';

export default function Home() {
    return (
        // <page className="flex grow flex-col py-16 px-4 w-full max-w-screen-xl bg-red-500" id="browse-the-room">
        <page className="flex grow flex-col py-6 md:py-16 px-4 w-full max-w-screen-xl">
            <div className=" my-10 md:my-36">
                <img
                className='max-h-20 mx-auto'
                src="/images/content2/logo.png"
                alt="El-Store Logo"
            />
            </div>
            <div className='text-3xl my-3 md:my-20 font-bold w-full text-center'>Contacte-nos</div>
            <div className='flex flex-col md:flex-row w-full justify-around items-center'>
                <ContactSection Title="Telefone" Action={"tel:+351919191919"} Icon={FaPhoneAlt} Text={"+351 919 191 919"} />
                <ContactSection Title="Email" Action={"mailto:teste@email.com"} Icon={FaEnvelope} Text={"teste@email.com"} />
                <ContactSection Title="Morada" Icon={FaHome} Text={"R. Sitio do picapau amarelo n.0"} />
                <ContactSection Title="Instagram" Icon={FaInstagram} Text={"+351 919 191 919"} />
                <ContactSection Title="Facebook" Icon={FaFacebook} Text={"+351 919 191 919"} />
            </div>
            
        </page>
    )
}
